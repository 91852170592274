import * as React from "react";
import { css } from "@emotion/react";
import bg from "../images/bg.jpeg";
import { StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import buttonDisconnect from "../../src/images/button_disconnect.png";
import { bp } from "../breakpoints";

const IndexPage = () => {
  return (
    <main
      css={
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100vh;
          background: url(${ bg }) no-repeat center center;
          background-size: cover;
          overflow: hidden;
        `
      }
    >
      <div
        css={
          css`
            display: flex;
            flex-direction: column;
            height: 100vh;
            align-items: center;
            padding-top: 16vh;
          ` }
      >
        <StaticImage
          css={ css`
            height: 50vh;
            width: 50vh;
            max-height: 90vw;
            max-width: 90vw;
            //margin-bottom: 12px;
          ` }
          src={ "../images/horse.png" }
          objectFit={ "contain" }
          placeholder={ "none" }
          loading={ "eager" }
          alt={ "background" }
        />
        <a
          href={ "https://faucet.stablebattle.io" }
          css={ css`
            display: block;
            min-width: 55%;
          ` }
        >
          <ConnectionButton
            css={ css`
              background: url(${ buttonDisconnect }) no-repeat center;
              background-size: cover;
            ` }
          >JOIN ALPHA</ConnectionButton>
        </a>

        <div css={ css`flex: 1` }></div>


        <div
          css={ css`
            position: relative;
            width: 100%;
            max-width: 90vw;
            display: flex;
            height: 14vh;
            justify-content: center;
            align-items: center;
          `
          }
        >

          <a href={ "https://twitter.com/StableBattle" } target={ "_blank" }>
            <StaticImage
              css={ [
                socialIconStyles,
                css`
                  margin-right: 4vh;
                `,
              ] }
              src={ "../images/logo_twitter.png" }
              placeholder={ "none" }
              loading={ "eager" }
              alt={ "background" }
            />
          </a>
          <a href={ "https://discord.gg/g8cu7UfYnt" } target={ "_blank" }>
            <StaticImage
              css={ [
                socialIconStyles,
                css`
                  margin-right: 4vh;
                `,
              ] }
              src={ "../images/logo_discord.png" }
              placeholder={ "none" }
              loading={ "eager" }
              alt={ "background" }
            />
          </a>
          <a href={ "https://medium.com/@StableBattle" } target={ "_blank" }>
            <StaticImage
              css={ socialIconStyles }
              src={ "../images/logo_medium.png" }
              placeholder={ "none" }
              loading={ "eager" }
              alt={ "background" }
              shadow="1px 1px 1px 1px rgba(0,0,0,0.2)"
            />
          </a>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;

const socialIconStyles = css`
  width: 5vh;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(6px 6px 0 rgba(0, 0, 0));

  &:hover {
    transform: scale(1.05);
  }
`;

const ConnectionButton = styled.button`
  font-family: "Open Sans", "sans-serif";
  padding: 0.5em 1em;
  width: 100%;
  border: none;
  font-size: 1.2em;
  font-weight: 800;
  text-transform: uppercase;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
  text-shadow: 2px 2px 0 rgb(0 0 0 / 81%);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: white;

  ${ bp.xxl } {
    font-size: 1.6em;
  }

  &:hover {
    transform: scale(1.05);
  }
`;